import React, { useState, useEffect } from 'react';
import { Menu } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { menuData } from '@data/menuData';
import '@styles/BlogMenu.css';

const BlogMenu = ({ selectedTopLevelItem, handleChildMenuClick }) => {
  const [visibleSubMenus, setVisibleSubMenus] = useState({});
  const [activeItem, setActiveItem] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setActiveItem('');
  }, [selectedTopLevelItem]);

  const toggleSubMenu = (path) => {
    setVisibleSubMenus(prevState => {
      const newState = { ...prevState, [path]: !prevState[path] };

      // If the submenu is being closed, recursively close all its children
      if (!newState[path]) {
        const closeSubMenus = (subPath) => {
          Object.keys(newState).forEach(key => {
            if (key.startsWith(subPath)) {
              newState[key] = false;
            }
          });
        };
        closeSubMenus(path);
      }

      return newState;
    });
  };

  const handleItemClick = (e, { name, path }, hasSubItems) => {
    setActiveItem(name);
    navigate(path);
    if (handleChildMenuClick) {
      handleChildMenuClick(name);
    }

    // Ensure the submenu is expanded when a child node is clicked
    if (hasSubItems) {
      toggleSubMenu(path);
    } else {
      // Retract all sub-items when a sub-item is clicked
      setVisibleSubMenus({});
    }
  };

  const handleMouseLeave = () => {
    setVisibleSubMenus({});
  };

  const selectedMenu = selectedTopLevelItem 
    ? menuData.menu.find(item => item.title.toLowerCase() === selectedTopLevelItem.toLowerCase())
    : null;

  const renderMenuItems = (items, basePath = '', level = 1) => {
    return items.map((item, index) => {
      const currentPath = `${basePath}/${item.title.toLowerCase()}`;
      const hasSubItems = item.subItems && item.subItems.length > 0;
      const isActive = activeItem === item.title ? 'active' : '';

      return (
        <Menu.Item
          key={index}
          name={item.title}
          path={currentPath}
          className={isActive}
          onClick={(e) => { 
            e.stopPropagation(); 
            handleItemClick(e, { name: item.title, path: currentPath }, hasSubItems); 
          }}
        >
          {item.title}
          {hasSubItems && level < 3 && <i className="dropdown icon"></i>}
          {visibleSubMenus[currentPath] && hasSubItems && level < 3 && (
            <Menu.Menu className="submenu">
              {renderMenuItems(item.subItems, currentPath, level + 1)}
            </Menu.Menu>
          )}
        </Menu.Item>
      );
    });
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      <Menu secondary vertical className="blog-menu">
        {selectedMenu && renderMenuItems(selectedMenu.subItems || [], `/${selectedTopLevelItem.toLowerCase()}`)}
        <Menu.Item>
          <img src={`${process.env.PUBLIC_URL}/assets/FeedItems/BB0gXqnyuFCezfRCHB7.svg`} alt="Mosaic" size='tiny' />
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default BlogMenu;