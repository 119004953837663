import React, { useState } from 'react';
import { Menu, Image, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import '@styles/BlogHeader.css';
import '@styles/customColors.css';
import { menuData } from '@data/menuData';

const BlogHeader = ({ selectedTopLevelItem, setSelectedTopLevelItem }) => {
  const [activeItem, setActiveItem] = useState('home');
  const navigate = useNavigate();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    setSelectedTopLevelItem(name);
    navigate(`/${name.toLowerCase()}`);
  };

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <Menu.Item
        key={index}
        name={item.title}
        active={activeItem === item.title}
        onClick={handleItemClick}
      >
        <Button className={`custom-button ${activeItem === item.title ? 'active' : ''}`}>
          {item.title}
        </Button>
      </Menu.Item>
    ));
  };

  return (
    <div>
      <Menu secondary pointing className="header-menu">
        <Menu.Item className="logo-item" name="home" onClick={handleItemClick}>
          <Image src={`${process.env.PUBLIC_URL}/assets/CZRLogoCC.svg`} alt="Logo" className="header-logo" />
        </Menu.Item>
        {menuData && menuData.menu && renderMenuItems(menuData.menu)}
        <div className="header-text">Cloud Caesar</div>
      </Menu>
    </div>
  );
};

export default BlogHeader;