import React, { Component, createRef } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import BlogMenu from '@components/BlogMenu';
import '@styles/BlogBody.css';

class BlogBody extends Component {
  contextRef = createRef();
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { children, selectedTopLevelItem, handleChildMenuClick } = this.props;
    const { isHovered } = this.state;

    return (
      <div class="background-image">
      <Grid padded="vertically" >
        <Grid.Row stretched>
          <Grid.Column
            width={isHovered ? 4 : 2}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            className="blog-menu-column"
          >
            <BlogMenu
              selectedTopLevelItem={selectedTopLevelItem}
              handleChildMenuClick={handleChildMenuClick}
            />
          </Grid.Column>
          <Grid.Column width={isHovered ? 12 : 14} className="content-column">
            <div class="page-border">
              <Segment>
                {children}
              </Segment>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
    );
  }
}

export default BlogBody;