import React, { useState } from 'react';
import { Container, Header, Segment, Icon, Button, Grid, Image, Divider, Modal } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";
import '@styles/BlogBody.css';
import '@styles/GeneralTheme.css';
import WebToLead from '@components/WebToLead'; // Import the ContactForm component

const Home = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal visibility

  const categories2 = [
    {
      title: "About",
      description: "Cloud Caesar blends ancient wisdom with modern technology.",
      icon: "pagelines",
      color: "#702963",
    },
    {
      title: "Service",
      description: "Professional Salesforce Development & Technology Consulting.",
      icon: "sun",
      color: "#702963",
    },
    {
      title: "Vision",
      description: "The vision for the company and the things we value.",
      icon: "dna",
      color: "#702963",
    },
  ];

  const categories = [
    {
      title: "History",
      description: "The stories of empires shaped the present and paved the future.",
      icon: "book",
      color: "#702963",
      subItems: [
        {
          title: "Ancient Civilizations",
          description: "Dive into the fascinating history of ancient societies.",
          image: "https://example.com/ancient.jpg",
        },
        {
          title: "Renaissance Insights",
          description: "Uncover the rebirth of knowledge and art.",
          image: "https://example.com/renaissance.jpg",
        },
      ],
    },
    {
      title: "Mindfulness",
      description: "Cultivating a balanced and mindful life through intentional practices.",
      icon: "leaf",
      color: "#702963",
      subItems: [
        {
          title: "Meditation Techniques",
          description: "Learn methods to quiet the mind and focus the body.",
          image: "https://example.com/meditation.jpg",
        },
        {
          title: "Daily Gratitude",
          description: "Harness the power of gratitude to enrich your life.",
          image: "https://example.com/gratitude.jpg",
        },
      ],
    },
    {
      title: "Technology",
      description: "Guiding you through tech’s storm, one mindful step at a time.",
      icon: "computer",
      color: "#702963",
      subItems: [
        {
          title: "JavaScript Frameworks",
          description: "Explore the latest in JavaScript technology and frameworks.",
          image: "https://example.com/javascript.jpg",
        },
        {
          title: "Salesforce Development",
          description: "Harness the power of Salesforce for scalable solutions.",
          image: "https://example.com/salesforce.jpg",
        },
      ],
    },
  ];

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container>
      <Segment textAlign='center' raised padded style={{ border: '2px solid #a9885c', padding: 0 }}>
        <Image src={`${process.env.PUBLIC_URL}/assets/SoPrettyLogo.svg`} alt="Logo"  />
      </Segment>

      <Divider section />
      
      <Segment raised padded>
        <Grid stackable columns={3} divided>
          {categories2.map((category, index) => (
            <Grid.Column key={index}>
              <Segment raised>
                <Header as="h3" icon style={{ color: '#a9885c' }}>
                  <Icon name={category.icon} className="custom-icon-body" />
                  {category.title}
                </Header>
                <p>{category.description}</p>
                <Button
                  fluid
                  className="custom-button-body"
                  onClick={() => navigate(`/${category.title.toLowerCase()}`)}
                >
                  Explore {category.title}
                </Button>
              </Segment>
            </Grid.Column>
          ))}
        </Grid>
      </Segment>

      <Divider section />

      <Segment raised padded>
        <Grid stackable columns={3} divided>
          {categories.map((category, index) => (
            <Grid.Column key={index}>
              <Segment raised>
                <Header as="h3" icon style={{ color: '#a9885c' }}>
                  <Icon name={category.icon} className="custom-icon-body" />
                  {category.title}
                </Header>
                <p>{category.description}</p>
                <Button
                  fluid
                  className="custom-button-body"
                  onClick={() => navigate(`/blog/${category.title.toLowerCase()}`)}
                >
                  Explore {category.title}
                </Button>
              </Segment>
            </Grid.Column>
          ))}
        </Grid>
      </Segment>

      <Divider section />

      {/* Call to Action Section */}
      <Segment textAlign='center' vertical style={{ color: '#a9885c' }}>
        <Header as='h2' icon style={{ color: '#a9885c' }}>
          <Icon name='mail' style={{ color: '#a9885c' }}/>
          Ready to Transform Your Business?
        </Header>
        <p>Contact us today to discuss how we can help you leverage technology for growth and efficiency.</p>
        <Button primary size='large' className="custom-button-body" onClick={handleOpenModal}>Get In Touch</Button>
      </Segment>

      {/* Contact Form Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal} size='small' style={{ backgroundColor: '#702963' }}>
        <Modal.Header style={{ backgroundColor: '#702963', color: '#a9885c' }}>Contact Us</Modal.Header>
        <Modal.Content style={{ backgroundColor: '#a9885c' }}>
          <WebToLead />
        </Modal.Content>
        <Modal.Actions style={{ backgroundColor: '#702963', color: '#a9885c' }}>
          <Button style={{ backgroundColor: '#a9885c', color: '#702963' }} onClick={handleCloseModal}>Close</Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default Home;