export const menuData = {
  "menu": [
    {
      "title": "home",
      "subItems": []
    },
    {
      "title": "about",
      "subItems": []
    },
    {
      "title": "blog",
      "subItems": [
        {
          "title": "history",
          "subItems": []
        },
        {
          "title": "mindfulness",
          "subItems": []
        },
        {
          "title": "technology",
          "subItems": [
            {
              "title": "javascript",
              "subItems": []
            },
            {
              "title": "salesforce",
              "subItems": []
            }
          ]
        }
      ]
    },
    {
      "title": "service",
      "subItems": []
    },
    {
      "title": "vision",
      "subItems": []
    }
  ]
};